import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SpinnerLoading from "../../components/common/spinnerLoading"
import { dateTimeToDate } from "../../utils/functions"

class News extends Component {
  state = {
    loading: true,
    news: [],
  }

  componentDidMount = async () => {
    const { news } = this.props.pageContext
    this.setState({ news, loading: false })
  }

  manageReadMoreClick = article => {
    window.location = "/articolo"
  }

  render() {
    const { news, loading } = this.state
    return (
      <Layout>
        <SEO title="News" description="News" />

        <div className="container">
          <div className={"row"}>
            <div className={"col-12"}>
              {loading && (
                <div className="my-5 py-5">
                  <SpinnerLoading />
                </div>
              )}

              {!loading && (
                <div>
                  <h1 className={"page-title"}>News</h1>

                  <div className={"row"}>
                    {news.map(article => (
                      <div className="col-12 col-md-4 mb-5" key={article.id}>
                        <article className="news-item">
                          <header className={"news-item-header"}>
                            {article.attributes &&
                              article.relationships.media.data.length > 0 && (
                                <img
                                  src={
                                    article.relationships.media.data[0]
                                      .attributes.url
                                  }
                                  className="news-item-image"
                                  alt={article.attributes.title}
                                />
                              )}

                            <h4 className={"news-item-title"}>
                              {article.attributes.title}
                            </h4>
                            <div className={"news-item-published-at"}>
                              <span className="sr-only">Pubblicato il: </span>
                              <small>
                                {dateTimeToDate(article.attributes.updated_at)}
                              </small>
                            </div>
                          </header>

                          <div className={"news-item-body"}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: article.attributes.excerpt,
                              }}
                            />
                          </div>

                          <footer className={"news-item-footer"}>
                            <a
                              className="btn btn-primary btn-sm"
                              href={"/news/articolo/" + article.attributes.slug}
                            >
                              Leggi di più
                            </a>
                          </footer>
                        </article>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default News
